import { createContext, useContext } from "react";
import { CONST } from "./const";

// custom subdomain based on request

export const isTenantURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[1];

  return typeof subdomain == "string" && subdomain == "tenant";
};

export const IsTenantContext = createContext(false);

export const useIsTenant = () => {
  return useContext(IsTenantContext);
};

export const isBusinessURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  return typeof subdomain == "string" && subdomain.includes("business");
};

export const IsBusinessContext = createContext(false);

export const useIsBusiness = () => {
  return useContext(IsBusinessContext);
};

export const isGateURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = CONST.IS_DEV ? "ares-gate" : "gate";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isMalukuURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = CONST.IS_DEV ? "ares-maluku-gate" : "maluku-gate";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isTrenggalekURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "trenggalekkab";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isPesawaranURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "pesawarankab";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isMuaroJambiURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "muarojambikab";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isJambiURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "jambikota";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isMenpanURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "menpan";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isMenkesURL = (url: string) => {
  let domain = url;

  if (CONST.IS_DEV) {
    const subdomain = domain.split(".")[0];
    const allowedSubdomains = ["menkes", "kemkes"];
    return (
      typeof subdomain == "string" && allowedSubdomains.includes(subdomain)
    );
  } else {
    return (
      domain.includes("kms.kemkes.go.id") ||
      domain.includes("kemkes.gokampus.com")
    );
  }
};

export const isBpsURL = (url: string) => {
  let domain = url;

  if (CONST.IS_DEV) {
    const subdomain = domain.split(".")[0];
    const slugGate: string = "bps";
    return typeof subdomain == "string" && subdomain === slugGate;
  } else {
    return (
      domain.includes("lms.bps.go.id") || domain.includes("bps.gokampus.com")
    );
  }
};

export const isWonderfulLearningURL = (url: string) => {
  let domain = url;

  return domain.includes("wlc.kemenparekraf.go.id");
};

export const isPpdsmAparaturURL = (url: string) => {
  let domain = url;

  return domain.includes("ppsdm.atrbpn.go.id");
};

export const isBlueBirdURL = (url: string) => {
  let domain = url;

  // const subdomain = domain.split(".")[0];
  // const slugGate: string = "bluebird";
  // return typeof subdomain == "string" && subdomain === slugGate;

  return domain.includes("e-learning.bluebirdgroup.com");
};

export const isGokAresURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "gok-ares-enterprise";
  return (
    (typeof subdomain == "string" && subdomain === slugGate) ||
    domain.includes("gok-ares-enterprise.gokampus.com")
  );
};

export const isGaiaURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "gaia";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isKemendagriURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "kemendagri";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isCimbniagaURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "cimbniaga";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isSreeyaSewuURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "sreeyasewu";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isSimURL = (url: string) => {
  let domain = url;
  const subdomain = domain.split(".")[0];
  const slugGate: string = "sim";
  return typeof subdomain == "string" && subdomain === slugGate;
};

export const isMoodleURL = (url: string) => {
  let domain = url;

  if (CONST.IS_DEV) {
    return domain.includes("moodle-ares.gokampus.com");
  } else {
    return domain.includes("moodle.tenant.gokampus.com");
  }
};

export const IsGateContext = createContext(false);
export const IsGateProvinceContext = createContext(false);

export const useIsGate = () => {
  return useContext(IsGateContext);
};

export const useIsGateProvince = () => {
  return useContext(IsGateProvinceContext);
};

export const getSubDomain = (hostname: string) => {
  return hostname.split(".")[0] ?? "";
};

export const IsSubdomainContext = createContext(false);

export const useIsSubdomain = () => {
  return useContext(IsSubdomainContext);
};
